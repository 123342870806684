import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import ReactMarkdown from "react-markdown/with-html";

//components
import useSetPageData from "../../../utils/useSetPageData";
import Hero from "../../../components/panels/Hero";
// import OtherServices from "../../components/panels/OtherServices";
import ComponentHeader from "../../../components/panels/ComponentHeader";
import Collapsible from "../../../components/panels/Collapsible";
import ListItem from "../../../components/panels/ListItem";
import SwitchComponent from "../../../components/panels/Switch";
import SeoComponent from "../../../components/seo";

const IbmSterlingPage = ({ location, data }) => {
  const {
    SEO,
    hero,
    overview,
    ibm_overview_image,
    overview_cards,
    benefits_cards,
    learn_more
  } = data.strapiPsIbm;

  // useSetPageData(location, {
  //   title: `${hero[0].main_heading} | Solutions`,
  //   description: SEO.description
  // });

  return (
    <>
      <SeoComponent
        {...{
          title: `${hero[0].main_heading} | Solutions`,
          description: SEO.description
        }}
      />
      <Hero
        heading={hero[0].main_heading}
        icon={hero[0].service_icon.childImageSharp.fixed}
        // videoImage={hero[0].services_main_image.childImageSharp.fixed.src}
        // videoLink={"/videos/final-cloud.mp4"}
      />
      <div className={`container-fluid`}></div>
      <SwitchComponent
        contents={[
          {
            image: <Image fluid={ibm_overview_image.childImageSharp.fluid} />,
            content: <ReactMarkdown source={overview} escapeHtml={false} />
          }
        ]}
      />
      <ListItem
        columnsPerRow={2}
        isCard
        bgClassName={`p-0`}
        contents={overview_cards.map(card => {
          return {
            image: <Image fixed={card.card_image.childImageSharp.fixed} />,
            heading: card.heading,
            content: <ReactMarkdown source={card.content} escapeHtml={false} />
          };
        })}
      />
      <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"Your Benefits"}
      />
      <ListItem
        columnsPerRow={2}
        isCard
        contents={benefits_cards.map((card, i) => {
          return {
            image: <Image fixed={card.card_image.childImageSharp.fixed} />,
            heading: card.heading,
            content: <ReactMarkdown source={card.content} escapeHtml={false} />
          };
        })}
      />
      <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"Learn More"}
        id={"learn-more"}
      />
      <Collapsible
        rootClassName={`bg-white text-dark`}
        contents={learn_more.map((card, i) => {
          return {
            heading: card.heading,
            content: (
              <p className={`text-justify`}>
                <ReactMarkdown source={card.content} escapeHtml={false} />
              </p>
            )
          };
        })}
      />
      {/* <OtherServices
        services={["cloud", "it-consulting", "rpa", "virtual-assistant"]}
      /> */}
    </>
  );
};

export default IbmSterlingPage;

export const pageQuery = graphql`
  {
    strapiPsIbm {
      SEO {
        description
      }
      hero {
        main_heading
        main_subheading
        services_main_image {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        service_icon {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      overview
      ibm_overview_image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      overview_cards {
        heading
        content
        card_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }

      benefits_cards {
        heading
        content
        card_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }

      learn_more {
        heading
        content
      }
    }
  }
`;
